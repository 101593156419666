import styled from 'styled-components';
import { Col } from 'react-bootstrap';

export const BodyMiddle = styled.div`
  padding: 80px 110px;
  background: rgb(237, 237, 237);
  background: -moz-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(237, 237, 237, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(237, 237, 237, 1) 100%
  );
  blockquote {
    background-color: #fff;
  }
  @media (max-width: 1199px) {
    padding: 50px;
  }
  @media (max-width: 767px) {
    padding: 35px;
  }
`;

export const Column = styled(Col)`
  position: relative;
  padding-right: 50px !important;
  @media (max-width: 991px) {
    width: 100%;
    padding: 0px !important;
    margin-bottom: 30px !important;
    :last-child {
      margin-bottom: 0 !important;
    }
    *:last-child {
      margin-bottom: 0 !important;
    }
    ul:first-child {
      margin-top: -30px !important;
    }
  }
`;
